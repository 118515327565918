import React, { useEffect, useState } from 'react';
import styles from './AcctPortfolioPage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece, faBars, faTimes, faGun } from '@fortawesome/free-solid-svg-icons';
import AcctHeaderBar from './AcctHeaderBar';
import FooterBar from '../common/FooterBar';
import { devClient } from '../api/client';
import { useNavigate } from 'react-router-dom';

import ext_link_icn from '../../assets/icons/external-link.png'; 

const PortfolioPage = () => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [selectedApp, setSelectedApp] = useState(null);
  const [apps, setApps] = useState([]);
  const [newStatus, setNewStatus] = useState(null);
  const [refreshMessage, setRefreshMessage] = useState('');
  const navigate = useNavigate();

  const handleChangeStatus = async (version) => {
    const confirmChange = window.confirm(`Are you sure you want to change the status to ${newStatus}?`);
    if (confirmChange) {
      try {
        const accessToken = await localStorage.getItem('accessToken');
        await devClient.post(`/v1/agents/${selectedApp.agent_id}/status/`, { status: newStatus, version: version}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });
        alert('Status changed successfully');
        setRefreshMessage("Refresh the page to reflect correct status.");
      } catch (error) {
        console.error('Error changing status:', error);
        alert('Failed to change status');
      }
    }
  };

  useEffect(() => {
    const fetchApps = async () => {
      const accessToken = await localStorage.getItem('accessToken');
      const response = await devClient.get('/v1/agents/', {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setApps(response.data);
      console.log("Response received for portfolio: ", response.data);
    }

    fetchApps();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const handleRowClick = (app) => {
    setSelectedApp(app);
    setNewStatus(app.status === 'LIVE' ? 'SUSPENDED' : 'LIVE');
  };

  const closeAppSummary = () => {
    setSelectedApp(null);
    setNewStatus(null);
  };

  const createNewApp = () => {
    navigate('/35lz6vm87q425vd9-create-app');
  };

  const updateApp = (selectedApp) => {
    navigate('/35lz6vm87q425vd9-update-app', { state: { app_json: selectedApp } });
  };

  const previewApp = (event, selectedApp) => {
    //event.preventDefault(); 
    navigate('/35lz6vm87q425vd9-preview', { state: { app_json: selectedApp } });
  };

  const menuItems = [
    { label: 'Dashboard', href: '#dashboard' },
    { label: 'Docs', href: '#docs' },
    { label: 'Community', href: '#community' },
  ];

  return (
    <div className={styles.portfolioPage}>
      <AcctHeaderBar menuItems={menuItems} isSignedIn={true} />
      <div className={styles.mainContent}>
        <aside className={`${styles.sidebar} ${isSidebarExpanded ? styles.expanded : styles.collapsed}`}>
          <div className={styles.toggleBtn} onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faBars} />
          </div>
          {isSidebarExpanded && (
            <nav>
              <h2>Manager</h2>
              <ul>
                <li className={styles.active}>Portfolio</li>
                <li>Analytics</li>
                <li>Payments</li>
                <li>Account</li>
              </ul>
              <button className={styles.newApp} onClick={createNewApp}>New App</button>
            </nav>
          )}
        </aside>
        <main className={styles.content}>
          <h1>Manage your apps</h1>
          <table>
            <thead>
              <tr>
                <th>App name</th>
                <th>Version</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {apps.map((app, index) => (
                <tr key={index} onClick={() => handleRowClick(app)}>
                  <td>
                    <FontAwesomeIcon icon={faPuzzlePiece} />
                    <a href="#">{app.name}</a>
                  </td>
                  <td>{app.version}</td>
                  <td>
                    <span className={`${styles.status} ${styles[app.status.toLowerCase()]}`}>{app.status}</span>
                  </td>
                  <td><a href="#">{app.pending_actions_count}</a></td>
                </tr>
              ))}
            </tbody>
          </table>
        </main>
        <aside className={`${styles.appSummary} ${selectedApp ? styles.expanded : styles.collapsed}`}>
          {selectedApp && (
            <div className={styles.summaryContent}>
              <div className={styles.summaryHeader}>
                <h2>{selectedApp.name}
                <a href="#" onClick={(event) => previewApp(event, selectedApp)}><img src={ext_link_icn} alt="External Link" className={styles.externalLinkIcon} /></a>
                </h2>
                <button className={styles.closeBtn} onClick={closeAppSummary}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <p><strong>Agent Id:</strong> {selectedApp.agent_id}</p>
              <p><strong>Version:</strong> {selectedApp.version}</p>
              <p><strong>Status:</strong> {selectedApp.status}</p>

              {(selectedApp.status === 'LIVE' || selectedApp.status === 'SUSPENDED') && (
                <div className={styles.statusChangeRow}>
                  <select value={newStatus} onChange={(e) => setNewStatus(e.target.value)} className={styles.statusDropdown}>
                    {selectedApp.status === 'LIVE' && (
                      <>
                        <option value="SUSPENDED">SUSPENDED</option>
                        <option value="EXITED">EXITED</option>
                      </>
                    )}
                    {selectedApp.status === 'SUSPENDED' && (
                      <>
                        <option value="LIVE">LIVE</option>
                        <option value="EXITED">EXITED</option>
                      </>
                    )}
                  </select>
                  <button className={styles.confirmBtn} onClick={() => handleChangeStatus(selectedApp.version)}>Change Status</button>
                </div>
              )}

              <div className={styles.refreshMessage}>{refreshMessage}</div>
              <p><strong>Summary:</strong> {selectedApp.summary}</p>
              <p><strong>Description:</strong></p>
              <>{selectedApp.description}</>
              <p><strong>Actions:</strong> {selectedApp.pending_actions_count}</p>
              <p><strong>Actions List:</strong> </p>

              {selectedApp.pending_actions && selectedApp.pending_actions.length > 0 ? (
                <div className={styles.pendingActionList}>{selectedApp.pending_actions.map((action, index) => (
                  <p key={index}> <FontAwesomeIcon icon={faGun} /> {action.description}</p>
                ))}</div>
              ) : (
                <p className={styles.noPendingActions}>No pending actions</p>
              )}
              <button className={styles.newApp} onClick={() => updateApp(selectedApp)}>Update App</button>
            </div>
          )}
        </aside>
      </div>
      <FooterBar/>
    </div>
  );
};

export default PortfolioPage;