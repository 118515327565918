// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import {useAuth } from './AuthContext';

const PrivateRoute = ({ component: Component, allowAccess }) => {
  const { currentUser } = useAuth();
  console.log("Current user ", currentUser);

  if (!currentUser) {
    return <Navigate to="/35lz6vm87q425vd9-login" />;
  }

  console.log("Is user eligible: ", currentUser.eligible == 1 )


  // Check if access is allowed
  if (allowAccess && !allowAccess(currentUser)) {
    return <Navigate to="/35lz6vm87q425vd9-developer-approval" />;
  }

  return Component;

};

export default PrivateRoute;
