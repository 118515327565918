import React, { useState, useRef, useEffect } from 'react';
import styles from './AcctAppCreatePage.module.css'; // Import the CSS module
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faUpload } from '@fortawesome/free-solid-svg-icons';
import AcctHeaderBar from './AcctHeaderBar';
import FooterBar from '../common/FooterBar';
import { devClient } from '../api/client';
import { useNavigate, useLocation } from 'react-router-dom';

const categories = [
  {
    category: "Programming",
    subcategory: ["C", "Java", "Python"]
  },
  {
    category: "Finance",
    subcategory: ["Debt", "Invest", "PPF"]
  }
];

const AcctAppUpdatePage = () => {
  const location = useLocation();
  const appJson = location.state ? location.state.app_json : null;

  const [appName, setAppName] = useState('');
  const [summary, setSummary] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [subcategories, setSubcategories] = useState([]);
  const [configurations, setConfigurations] = useState([{ key: '', value: '' }]);
  const [appFile, setAppFile] = useState(null);
  const [appFileName, setAppFileName] = useState('');
  
  const [appBeFile, setAppBeFile] = useState(null);
  const [appBeFileName, setAppBeFileName] = useState('');

  const [appIcon, setAppIcon] = useState(null);
  const [appIconName, setAppIconName] = useState('');

  const [screenShot, setScreenShot] = useState(null);
  const [screenShotName, setScreenShotName] = useState('');

  const appFileInputRef = useRef(null);
  const appBeFileInputRef = useRef(null);
  const appIconInputRef = useRef(null);
  const screenShotsInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const selectedCategory = categories.find(cat => cat.category === category);
    if (selectedCategory) {
      setSubcategories(selectedCategory.subcategory);
    } else {
      setSubcategories([]);
    }
  }, [category]);

  useEffect(() => {
    if (appJson) {
      setAppName(appJson.name);
      setSummary(appJson.summary);
      setDescription(appJson.description);
      setCategory(appJson.category);
      setSubcategory(appJson.subcategory);
      setConfigurations(appJson.configuration);
    }
  }, [appJson]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('agent_id', appJson.agent_id)
    formData.append('name', appName);
    formData.append('summary', summary);
    formData.append('description', description);
    formData.append('category', category);
    formData.append('subcategory', subcategory);
    formData.append('configuration', JSON.stringify(configurations));

    if (appFile) {
      formData.append('app', appFile);
    }
    if (appBeFile) {
      formData.append('app_be', appBeFile);
    }
    if (appIcon) {
      formData.append('app_icon', appIcon);
    }
    if (screenShot) {
      formData.append('app_screenshot', screenShot);
    }

    try {
      const accessToken = await localStorage.getItem('accessToken');
      const response = await devClient.put(`/v1/agents/${appJson.agent_id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log('Form submitted successfully:', response.data);
      navigate('/35lz6vm87q425vd9-portfolio');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleConfigChange = (index, field, value) => {
    const newConfigurations = [...configurations];
    newConfigurations[index][field] = value;
    setConfigurations(newConfigurations);
  };

  const addConfiguration = () => {
    setConfigurations([...configurations, { key: '', value: '' }]);
  };

  const removeConfiguration = (index) => {
    if (configurations.length > 1) {
      const newConfigurations = configurations.filter((_, i) => i !== index);
      setConfigurations(newConfigurations);
    }
  };

  const isPairFilled = (config) => {
    return config.key.trim() !== '' && config.value.trim() !== '';
  };

  const isLastPair = (index) => {
    return index === configurations.length - 1;
  };

  const handleFileChange = (setter, setName) => (e) => {
    const file = e.target.files[0];
    setter(file);
    setName(file ? file.name : '');
  };

  const menuItems = [
    { label: 'Dashboard', href: '#dashboard' },
    { label: 'Docs', href: '#docs' },
    { label: 'Community', href: '#community' },
  ];

  return (
    <div className={styles.uploadPage}>
      <AcctHeaderBar menuItems={menuItems} isSignedIn={true} />

      <main>
        <h1>Update App</h1>
        <p>Start by updating the Zip package for your app</p>

        <form onSubmit={handleSubmit}>
          <div className={styles.formLayout}>
            <div className={styles.formLeft}>
              <div className={styles.formGroup}>
                <label htmlFor="appName">App name</label>
                <input
                  type="text"
                  id="appName"
                  value={appName}
                  onChange={(e) => setAppName(e.target.value)}
                  placeholder="My app"
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="summary">Summary</label>
                <textarea
                  id="summary"
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                  placeholder="Write One-Liner summary of app here..."
                  rows="1"
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="description">Description</label>
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Describe your app here..."
                  rows="5"
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="category">Category</label>
                <select
                  id="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">Select a category</option>
                  {categories.map((cat, index) => (
                    <option key={index} value={cat.category}>{cat.category}</option>
                  ))}
                </select>
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="subcategory">Subcategory</label>
                <select
                  id="subcategory"
                  value={subcategory}
                  onChange={(e) => setSubcategory(e.target.value)}
                >
                  <option value="">Select a subcategory</option>
                  {subcategories.map((subcat, index) => (
                    <option key={index} value={subcat}>{subcat}</option>
                  ))}
                </select>
              </div>

              <div className={styles.formGroup}>
                <label>Configuration</label>
                {configurations.map((config, index) => (
                  <div key={index} className={styles.configRow}>
                    <input
                      type="text"
                      placeholder="Key"
                      value={config.key}
                      onChange={(e) => handleConfigChange(index, 'key', e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Value"
                      value={config.value}
                      onChange={(e) => handleConfigChange(index, 'value', e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={() => removeConfiguration(index)}
                      className={styles.configBtn}
                      disabled={configurations.length === 1}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <button
                      type="button"
                      onClick={addConfiguration}
                      className={styles.configBtn}
                      disabled={!isLastPair(index) || !isPairFilled(config)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                ))}
              </div>

              <button type="submit" className={styles.submitBtn}>Update App</button>
            </div>

            <div className={styles.formRight}>
              <div className={styles.uploadSection}>
                <h2>App artifact - Frontend</h2>
                <div className={styles.uploadContent} onClick={() => appFileInputRef.current.click()}>
                  <div className={styles.uploadIcon}>
                    <FontAwesomeIcon icon={faUpload} />
                  </div>
                  <p>Upload Zip</p>
                  {appFileName && <p className={styles.fileName}>{appFileName}</p>}
                  <input
                    type="file"
                    accept=".zip"
                    onChange={handleFileChange(setAppFile, setAppFileName)}
                    className={styles.uploadInput}
                    ref={appFileInputRef}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>

              <div className={styles.uploadSection}>
                <h2>App artifact - Backend</h2>
                <div className={styles.uploadContent} onClick={() => appBeFileInputRef.current.click()}>
                  <div className={styles.uploadIcon}>
                    <FontAwesomeIcon icon={faUpload} />
                  </div>
                  <p>Upload Zip</p>
                  {appBeFileName && <p className={styles.fileName}>{appBeFileName}</p>}
                  <input
                    type="file"
                    accept=".zip"
                    onChange={handleFileChange(setAppBeFile, setAppBeFileName)}
                    className={styles.uploadInput}
                    ref={appBeFileInputRef}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>

              <div className={styles.uploadSection}>
                <h2>App logo</h2>
                <div className={styles.uploadContent} onClick={() => appIconInputRef.current.click()}>
                  <div className={styles.uploadIcon}>
                    <FontAwesomeIcon icon={faUpload} />
                  </div>
                  <p>Upload image</p>
                  {appIconName && <p className={styles.fileName}>{appIconName}</p>}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange(setAppIcon, setAppIconName)}
                    className={styles.uploadInput}
                    ref={appIconInputRef}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>

              <div className={styles.uploadSection}>
                <h2>Banner</h2>
                <div className={styles.uploadContent} onClick={() => screenShotsInputRef.current.click()}>
                  <div className={styles.uploadIcon}>
                    <FontAwesomeIcon icon={faUpload} />
                  </div>
                  <p>Upload image</p>
                  {screenShotName && <p className={styles.fileName}>{screenShotName}</p>}
                  <p className={styles.uploadInfo}>
                    Background image must be PNG or JPEG, up to 8MB each, and at least 320px on the shortest side.
                  </p>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange(setScreenShot, setScreenShotName)}
                    className={styles.uploadInput}
                    ref={screenShotsInputRef}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>

      <FooterBar />
    </div>
  );
};

export default AcctAppUpdatePage;
