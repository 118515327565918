import React from 'react';
import styles from './DevPage.module.css'; // Import the CSS module
import  HeaderBar from '../common/HeaderBar'; // Import the HeaderNavBar component

import api_icn from '../../assets/icons/api.png'; 
import dist_icn from '../../assets/icons/distribute.png'; 
import eng_icn from '../../assets/icons/engage.png'; 
import web_icn from '../../assets/icons/web.png'; 
import FooterBar from '../common/FooterBar';

const DevPage = () => {

  
  const menuItems = [
    { type: 'link', label: 'Docs', href: '#' },
    { type: 'link', label: 'API Reference', href: '#' },
    { type: 'link', label: 'Learn', href: '#' },
    { type: 'link', label: 'Community', href: '#' },
    { type: 'button', label: 'Get Started', onClick: () => window.location.href = '/35lz6vm87q425vd9-create-account', className: 'getStarted' },
    { type: 'button', label: 'Sign In', onClick: () => window.location.href = '/35lz6vm87q425vd9-login', className: 'signIn' },
  ];

  const createAccountFn = () => {
    window.location.href = '/35lz6vm87q425vd9-create-account';
  };

  return (
    <div className={styles.DevPage}>
      <HeaderBar menuItems={menuItems} />
      <main>
        <div className={styles.hero}>
          <div className={styles.heroContent}>
            <h1>Build with Maze</h1>
            <p>Maze Developer Portal is your one-stop shop for building with our APIs, SDKs, and developer tools.</p>
          </div>

          <div className={styles.heroBackground}>
            {/* Placeholder for background SVG */}
          </div>

          <section className={styles.cta}>
            <button className={styles.createAccountBtn} onClick={createAccountFn}>Create an account</button>
          </section>
        </div>

        <section className={styles.features}>
          <h2>Why build with Maze?</h2>
          <p>Maze empowers developers to build, grow, and monetize their apps and games. Whether you're a seasoned developer or just getting started, we're here to help you succeed.</p>
          <div className={styles.featureCards}>
            <div className={styles.featureCard}>
              <img src={api_icn} alt="Logo" />
              <h3>Powerful APIs & SDKs</h3>
              <p>Explore our APIs and developer tools to build the next big thing.</p>
            </div>
            <div className={styles.featureCard}>
              <img src={dist_icn} alt="Logo" />
              <h3>Distribute through Maze</h3>
              <p>Grow your business by distributing your app or service through Maze.</p>
            </div>
            <div className={styles.featureCard}>
              <img src={eng_icn} alt="Logo" />
              <h3>Engage with Maze</h3>
              <p>Engage with your users and get discovered through our platform.</p>
            </div>
            <div className={styles.featureCard}>
              <img src={web_icn} alt="Logo" />
              <h3>Global reach</h3>
              <p>Reach millions of users in 190+ countries and territories around the world.</p>
            </div>
          </div>
        </section>

      </main>
      <FooterBar/>
    </div>
  );
}

export default DevPage;
