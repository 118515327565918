import React, { useState, useEffect, useRef } from 'react';
import styles from './AcctAppPreviewPage.module.css';
import AcctHeaderBar from './AcctHeaderBar';
import FooterBar from '../common/FooterBar';
import { useLocation } from 'react-router-dom';
import JSZip from 'jszip';
import { devClient } from '../api/client';

const AppPreviewPage = () => {
    const location = useLocation();
    const appJson = location.state?.app_json;

    const [appName, setAppName] = useState('');
    const [agentId, setAgentId] = useState('');
    const [version, setVersion] = useState('');
    const [summary, setSummary] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [configurations, setConfigurations] = useState([]);
    const [webviewContent, setWebviewContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const iframeRef = useRef(null);

    useEffect(() => {
        if (appJson) {
            setAppName(appJson.name || '');
            setSummary(appJson.summary || '');
            setDescription(appJson.description || '');
            setCategory(appJson.category || '');
            setSubcategory(appJson.subcategory || '');
            setConfigurations(appJson.configuration || []);
            setAgentId(appJson.agent_id || '');
            setVersion(appJson.version || '');
        }
    }, [appJson]);

    useEffect(() => {
        if (agentId) {
            downloadAndExtractWebview(agentId);
        }
    }, [agentId]);

    const downloadAndExtractWebview = async (appId) => {
        setIsLoading(true);
        setError(null);
        try {
            const accessToken = await localStorage.getItem('accessToken');
            const response = await devClient.get(`/v1/agents/${appId}/download`, {
                responseType: 'arraybuffer',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            const zip = await JSZip.loadAsync(response.data);
            const files = new Map();

            await Promise.all(
                Object.keys(zip.files).map(async (filename) => {
                    const file = zip.files[filename];
                    if (!file.dir) {
                        const content = await file.async("string");
                        files.set(filename, content);
                    }
                })
            );

            let indexFilePath = [...files.keys()].find(path => path.endsWith('index.html'));

            if (indexFilePath) {
                let indexContent = files.get(indexFilePath);
                
                // Inject custom fetch and XMLHttpRequest functions
                const injectedScript = `
<script>
(function() {
    const originalFetch = window.fetch;
    window.fetch = async function(url, options) {
        const message = { type: 'fetchResource', url: url };
        window.parent.postMessage(message, '*');
        return new Promise((resolve, reject) => {
            window.addEventListener('message', function responseHandler(event) {
                if (event.data.type === 'fetchResponse' && event.data.url === url) {
                    window.removeEventListener('message', responseHandler);
                    if (event.data.error) {
                        reject(new Error(event.data.error));
                    } else {
                        resolve(new Response(event.data.content, {
                            headers: { 'Content-Type': event.data.contentType }
                        }));
                    }
                }
            });
        });
    };

    const originalXHROpen = window.XMLHttpRequest.prototype.open;
    const originalXHRSend = window.XMLHttpRequest.prototype.send;

    window.XMLHttpRequest.prototype.open = function(method, url, ...args) {
        this._url = new URL(url, window.location.origin).href;
        return originalXHROpen.call(this, method, this._url, ...args);
    };

    window.XMLHttpRequest.prototype.send = function(...args) {
        const xhr = this;
        const message = { type: 'fetchResource', url: this._url };
        window.parent.postMessage(message, '*');

        const listener = function(event) {
            if (event.data.type === 'fetchResponse' && event.data.url === xhr._url) {
                window.removeEventListener('message', listener);
                
                if (event.data.error) {
                    xhr.status = 404;
                    xhr.statusText = 'Not Found';
                    if (typeof xhr.onerror === 'function') {
                        xhr.onerror(new Error(event.data.error));
                    }
                } else {
                    xhr.status = 200;
                    xhr.statusText = 'OK';
                    xhr.response = event.data.content;
                    xhr.responseText = event.data.content;
                    xhr.responseType = 'text';
                    if (typeof xhr.onload === 'function') {
                        xhr.onload();
                    }
                }

                if (typeof xhr.onreadystatechange === 'function') {
                    xhr.readyState = 4;
                    xhr.onreadystatechange();
                }
            }
        };

        window.addEventListener('message', listener);

        // Don't actually send the request
        return undefined;
    };
})();
</script>
`;
                
                // Insert the injected script right after the opening <head> tag
                indexContent = indexContent.replace('<head>', '<head>' + injectedScript);
                
                const blob = new Blob([indexContent], { type: 'text/html' });
                const blobUrl = URL.createObjectURL(blob);

                setWebviewContent(blobUrl);

                // Set up message listener for resource requests
                const messageListener = (event) => {
                    if (event.data.type === 'fetchResource') {
                        const url = new URL(event.data.url);
                        const resourcePath = url.pathname.slice(1);  // Remove leading slash
                        if (files.has(resourcePath)) {
                            const content = files.get(resourcePath);
                            let contentType = 'text/plain';
                            if (resourcePath.endsWith('.js')) contentType = 'application/javascript';
                            if (resourcePath.endsWith('.css')) contentType = 'text/css';
                            if (resourcePath.endsWith('.html')) contentType = 'text/html';
                            event.source.postMessage({
                                type: 'fetchResponse',
                                url: event.data.url,
                                content: content,
                                contentType: contentType
                            }, '*');
                        } else {
                            event.source.postMessage({
                                type: 'fetchResponse',
                                url: event.data.url,
                                error: 'Resource not found'
                            }, '*');
                        }
                    }
                };

                window.addEventListener('message', messageListener);

                return () => {
                    window.removeEventListener('message', messageListener);
                };
            } else {
                throw new Error('index.html not found in the zip file');
            }
        } catch (err) {
            console.error("Error downloading or extracting webview content:", err);
            setError("Failed to load app preview");
        } finally {
            setIsLoading(false);
        }
    };


  const handleChangeStatus = async () => {
    const confirmChange = window.confirm(`Are you sure you want to approve this app changes?`);
    if (confirmChange) {
      try {
        const accessToken = await localStorage.getItem('accessToken');
        await devClient.post(`/v1/agents/${agentId}/approve/`, {version: version}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });
        alert('Status changed successfully');
      } catch (error) {
        console.error('Error changing status:', error);
        alert('Failed to change status');
      }
    }
  };

    const menuItems = [
        { label: 'Dashboard', href: '#dashboard' },
        { label: 'Docs', href: '#docs' },
        { label: 'Community', href: '#community' },
    ];

    return (
        <div className={styles.appPreviewPage}>
            <AcctHeaderBar menuItems={menuItems} isSignedIn={true} />
            <div className={styles.mainContent}>
                <div className={styles.leftPanel}>
                    <h1>App Information</h1>
                    <div className={styles.infoSection}>
                        <h2>App id:</h2>
                        <p>{agentId}</p>
                    </div>
                    <div className={styles.infoSection}>
                        <h2>Version:</h2>
                        <p>{version}</p>
                    </div>
                    <div className={styles.infoSection}>
                        <h2>Name</h2>
                        <p>{appName}</p>
                    </div>
                    <div className={styles.infoSection}>
                        <h2>Summary</h2>
                        <p>{summary}</p>
                    </div>
                    <div className={styles.infoSection}>
                        <h2>Description</h2>
                        <p>{description}</p>
                    </div>
                    <div className={styles.infoSection}>
                        <h2>Category</h2>
                        <p>{category}</p>
                    </div>
                    <div className={styles.infoSection}>
                        <h2>Subcategory</h2>
                        <p>{subcategory}</p>
                    </div>
                    <div className={styles.infoSection}>
                        <h2>Configuration</h2>
                        <pre>{JSON.stringify(configurations, null, 2)}</pre>
                    </div>

                    <div className={styles.infoSection}>
                    <button className={styles.confirmBtn} onClick={() => handleChangeStatus()}>Approve</button>
                    </div>
                </div>
                <div className={styles.rightPanel}>
                    <h1>App Preview</h1>
                    <div className={styles.webviewContainer}>
                        {isLoading ? (
                            <p>Loading preview...</p>
                        ) : error ? (
                            <p>{error}</p>
                        ) : webviewContent ? (
                            <iframe
                                ref={iframeRef}
                                src={webviewContent}
                                title="App Preview"
                                className={styles.webview}
                                sandbox="allow-scripts allow-same-origin"
                            />
                        ) : (
                            <p>No preview available</p>
                        )}
                    </div>
                </div>
            </div>
            <FooterBar />
        </div>
    );
};

export default AppPreviewPage;