// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AcctLoginPage from './components/developer/AcctLoginPage';
import ForgotPasswordPage from './components/developer/AcctForgotPassPage';
import AcctAppCreatePage from './components/developer/AcctAppCreatePage';
import AcctAppUpdatePage from './components/developer/AcctAppUpdatePage';
import DeveloperApprovalPage from './components/developer/AcctDevApprovalPage';
import DevPage from './components/developer/DevPage';
import AcctPortfolioPage from './components/developer/AcctPortfolioPage';
import AcctAppPreviewPage from './components/developer/AcctAppPreviewPage';
import AcctCreatePage from './components/developer/AcctCreatePage';
import { AuthProvider } from './components/context/AuthContext';
import PasswordResetSuccessful from './components/developer/AcctPassResetSuccess';

import Homepage from './components/public/Homepage';
import PrivateRoute from './components/context/PrivateRoute';

const App = () => {
  const hostname = window.location.hostname;

  let homeComponent, loginRoute, forgotPassRoute, devApprovalRoute, passResetRoute, createAppRoute, updateAppRoute, portfolioRoute, previewRoute, createAcctRoute;
  let tempHomeRoute;

  const eligibilityCheck = (user) => {
    return user.eligible == 1; 
  };
  
  if (hostname.includes('dev') || true) {
    homeComponent = DevPage;
    loginRoute = <Route path="/35lz6vm87q425vd9-login" element={<AcctLoginPage />} /> ;
    forgotPassRoute = <Route path="/35lz6vm87q425vd9-forgot-password" element={<ForgotPasswordPage />} /> ;
    devApprovalRoute = <Route path="/35lz6vm87q425vd9-developer-approval"  element={<PrivateRoute component={<DeveloperApprovalPage />} />} /> ;
    passResetRoute = <Route path="/35lz6vm87q425vd9-password-reset-successful" element={<PasswordResetSuccessful />} /> ;
    createAppRoute = <Route path="/35lz6vm87q425vd9-create-app" element={<PrivateRoute component={<AcctAppCreatePage />} allowAccess={eligibilityCheck} />} /> ; 
    updateAppRoute = <Route path="/35lz6vm87q425vd9-update-app" element={<PrivateRoute component={<AcctAppUpdatePage />} allowAccess={eligibilityCheck} />} /> ; 
    portfolioRoute = <Route path="/35lz6vm87q425vd9-portfolio" element={<PrivateRoute component={<AcctPortfolioPage />} allowAccess={eligibilityCheck} />} /> ;
    previewRoute = <Route path="/35lz6vm87q425vd9-preview" element={<PrivateRoute component={<AcctAppPreviewPage />} allowAccess={eligibilityCheck} />} /> ;
    createAcctRoute = <Route path="/35lz6vm87q425vd9-create-account" element={<AcctCreatePage />} /> ;
  } else {
    homeComponent = Homepage;
    tempHomeRoute = <Route path="/" element={<Homepage />} /> ;
  }

  return (
    <AuthProvider>
      <Router>
        <Routes>
        <Route path="/35lz6vm87q425vd9" element={<PrivateRoute element={React.createElement(homeComponent)} />} />

        /* Developer specific pages */
        {loginRoute}
        {forgotPassRoute}
        {devApprovalRoute}
        {passResetRoute}
        {createAppRoute}
        {updateAppRoute}
        {portfolioRoute}
        {previewRoute}
        {createAcctRoute}

        /* Public website specific pages */
        {tempHomeRoute}
      </Routes>
    </Router>
    </AuthProvider>
  );
};

export default App;
