import React, { useState } from 'react';
import styles from '../common/ContactModal.module.css';

const ContactModal = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = async (e) => {
    setIsSent(true);
    /* TODO: integrate with backend and open this.
    
    e.preventDefault();
    const formData = new FormData();
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('description', description);
    if (attachment) {
      formData.append('attachment', attachment);
    }

    try {
      const response = await fetch('/send-query', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setIsSent(true);
      } else {
        console.error('Failed to send query');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    */
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        {!isSent ? (
          <form onSubmit={handleSubmit}>
            <h2>Contact Support</h2>
            
            <div className={styles.inputGroup}>
              <label htmlFor="email">Your contact email</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email address"
              />
            </div>
            
            <div className={styles.inputGroup}>
              <label htmlFor="subject">Subject</label>
              <input
                id="subject"
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
                placeholder="Enter the subject of your query"
              />
            </div>
            
            <div className={styles.inputGroup}>
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                placeholder="Describe your issue or question"
              ></textarea>
            </div>
            
            <div className={styles.inputGroup}>
              <label htmlFor="attachment">Attachment</label>
              <input
                id="attachment"
                type="file"
                onChange={(e) => setAttachment(e.target.files[0])}
              />
            </div>
            
            <div className={styles.buttonGroup}>
              <button type="submit">Send</button>
              <button type="button" onClick={onClose} className={styles.cancelButton}>Cancel</button>
            </div>
          </form>
        ) : (
          <div className={styles.successMessage}>
            <h2>Query Sent Successfully</h2>
            <p>Thank you for contacting us. We'll get back to you soon.</p>
            <button onClick={onClose}>Close</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactModal;