import React, { useState } from 'react';
import styles from './AcctDevApprovalPage.module.css';
import AcctHeaderBar from './AcctHeaderBar';
import ContactModal from '../common/ContactModal';
import FooterBar from '../common/FooterBar';

const DeveloperApprovalPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const menuItems = [
 //   { label: 'Dashboard', href: '#dashboard' },
    { label: 'Docs', href: '#docs' },
    { label: 'Community', href: '#community' },
  ];

  return (
    <div className={styles.approvalPage}>
      <AcctHeaderBar menuItems={menuItems} isSignedIn={true} />
      <div className={styles.contentWrapper}>
        <h1>Welcome to the Developer Portal</h1>
        <p>Thank you for creating your account.</p>
        <p>Your account is currently pending approval. You will be able to access the full Developer Portal once your account has been verified and approved.</p>
        <p>This process typically takes few business days. We appreciate your patience.</p>
        <p>If you have any questions or concerns, please don't hesitate to contact us.</p>
        <button onClick={() => setIsModalOpen(true)} className={styles.contactLink}>Contact Support</button>
      </div>
      <FooterBar/>
      {isModalOpen && <ContactModal onClose={() => setIsModalOpen(false)} />}
    </div>
  );
};

export default DeveloperApprovalPage;