import React from 'react';
import styles from './HeaderBar.module.css'; // Import the CSS module
import logo from '../../assets/logo.jpeg'; 

const HeaderNavBar = ({ menuItems }) => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.brand}>
        <img src={logo} alt="Logo" />
        <div>MAZE</div>
      </div>
      <div className={styles.nav}>
        {menuItems.map((item, index) => {
          if (item.type === 'link') {
            return <a key={index} href={item.href} onClick={item.onClick}>{item.label}</a>;
          } else if (item.type === 'button') {
            return <button key={index} onClick={item.onClick} className={styles[item.className]}>{item.label}</button>;
          } else if (item.type === 'custom') {
            return <div key={index} className={styles[item.className]}>{item.content}</div>;
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default HeaderNavBar;
