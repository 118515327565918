// src/contexts/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { devClient } from '../api/client';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const accessToken = await localStorage.getItem('accessToken');
        console.log("AccessToken is : ", accessToken)
        const response = await devClient.get('/v1/developer/current/', {
          headers: { 
            Authorization: `Bearer ${accessToken}` 
          },
        });
        console.log("Response for current: ", response.data);
        setCurrentUser(response.data);
      } catch (error) {
        console.error('Failed to fetch current user', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCurrentUser();

  }, []);

  const login = async (credentials) => {
    try {
      const response = await devClient.post('/v1/developer/login/', credentials);
      setCurrentUser(response.data.user);
      console.log("Response for login: ", response.data)
      const accessToken = response.data.tokens.accessToken;
      console.log("Storing accessToken: ", accessToken)
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', response.data.tokens.refreshToken);

      console.log("AccessToken stored: ", localStorage.getItem('accessToken'));
    } catch (error) {
      console.error('Login failed', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      // const accessToken = await localStorage.getItem('accessToken');
      // console.log("AccessToken is : ", accessToken)
      // const response = await devClient.get('/v1/developer/logout/', {
      //   headers: { 
      //     Authorization: `Bearer ${accessToken}` 
      //   }
      //   ,
      // });
      // console.log("Response for logout: ", response.data);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      setCurrentUser(null);
    } catch (error) {
      console.error('Failed to fetch current user', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
